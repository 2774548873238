<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <h4 class="card-title mb-50 mb-sm-0">
      Активные сделки
    </h4>
    <b-table
        :items="orders"
        :fields="fields"
        striped
        responsive
        class="mb-0 orders-table"
    >
      <!-- pair -->
      <template #cell(currency_pair)="data">
        <div class="d-flex align-items-center">
          <b-avatar
              size="32"
              variant="light-company"
          >
            <b-img
                :src="data.item.coin_img"
                alt="avatar img"
            /></b-avatar>
          <div>
            <div class="font-weight-bolder">
              {{ data.item.currency_pair }}
            </div>
          </div>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BAvatar, BImg,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      fields: [
        { key: 'currency_pair', label: 'Пара' },
        { key: 'position', label: 'Тип' },
        { key: 'adjust_leverage', label: 'Плечо' },
        { key: 'price', label: 'Цена' },
        { key: 'quantity', label: 'Кол-во' },
        { key: 'current_price', label: 'Текущая цена' },
        { key: 'take_profit', label: 'Take' },
        { key: 'stop_loss', label: 'Stop' },
        { key: 'date', label: 'Время' },
      ],
    }
  },
  computed: {
    ...mapState({
      orders: state => state.order.orders,
    }),
  },
  mounted() {
    this.getItems()
  },
  methods: {
    ...mapActions({
      getItems: 'order/getActiveOrders',
    }),
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

h4 {
  padding: 1.5rem;
  color: #d0d2d6;
}

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}

[dir=ltr] .card-company-table td .b-avatar.badge-light-company {
  margin-right: 15px;
}

.card-company-table img {
  max-width: 32px;
}

.font-weight-bolder {
  white-space: nowrap;
}
</style>
