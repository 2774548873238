<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <h4 class="card-title mb-50 mb-sm-0">
      Кошелек
    </h4>
    <b-table
      :items="futuresWallet"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <!-- company -->
      <template #cell(asset)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            size="32"
            variant="light-company"
          >
            <b-img
              :src="data.item.img"
              alt="avatar img"
            /></b-avatar>
          <div>
            <div class="font-weight-bolder">
              {{ data.item.asset }}
            </div>
          </div>
        </div>
      </template>

      <!-- revenue -->
      <template #cell(balance)="data">
        {{ '$'+data.item.balance }}
      </template>

      <template #cell(availableBalance)="data">
        {{ '$'+data.item.availableBalance }}
      </template>

      <!-- sales -->
      <template #cell(crossUnPnl)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.crossUnPnl+'%' }}</span>
          <feather-icon
            :icon="data.item.crossUnPnl <= 0 ? 'TrendingDownIcon':'TrendingUpIcon'"
            :class="data.item.crossUnPnl <= 0 ? 'text-danger':'text-success'"
          />
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BAvatar, BImg,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      fields: [
        { key: 'asset', label: 'Токен' },
        { key: 'crossUnPnl', label: 'PNL' },
        { key: 'balance', label: 'Баланс' },
        { key: 'availableBalance', label: 'Доступный баланс' },
      ],
    }
  },
  computed: {
    ...mapState({
      futuresWallet: state => state.binance.futuresWallet,
    }),
  },
  mounted() {
    this.getFuturesWallet()
  },
  methods: {
    ...mapActions({
      getFuturesWallet: 'binance/getFuturesWallet',
    }),
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

h4 {
  padding: 1.5rem;
  color: #d0d2d6;
}

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}

[dir=ltr] .card-company-table td .b-avatar.badge-light-company {
  margin-right: 15px;
}

.card-company-table img {
  max-width: 32px;
}
</style>
