<template>
  <b-card>
    <b-card-title class="mb-1">
      Cтатистика сигналов
    </b-card-title>

    <vue-apex-charts
        type="donut"
        height="350"
        :options="apexChatData.donutChart.chartOptions"
        :series="apexChatData.donutChart.series"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
  },
  data() {
    return {
      apexChatData,
    }
  },
}
</script>
