<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col lg="4">
        <ecommerce-statuses :data="data.statisticsProfit" />
      </b-col>
      <!-- Company Table Card -->
      <b-col lg="8">
        <ecommerce-wallet-table :table-data="data.companyTable" />
      </b-col>
      <!--/ Company Table Card -->
      <!--/ Developer Meetup Card -->
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <ecommerce-statistics/>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col lg="12">
        <ecommerce-active-orders-table :table-data="data.companyTable" />
      </b-col>
      <!--/ Company Table Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { mapState } from 'vuex'
import EcommerceMedal from './EcommerceMedal.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'
import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
import EcommerceOrderChart from './EcommerceOrderChart.vue'
import EcommerceProfitChart from './EcommerceProfitChart.vue'
import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
import EcommerceMeetup from './EcommerceMeetup.vue'
import EcommerceBrowserStates from './EcommerceBrowserStates.vue'
import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
import EcommerceTransactions from './EcommerceTransactions.vue'
import EcommerceWalletTable from './EcommerceWalletTable.vue'
import EcommerceStatuses from './EcommerceStatuses.vue'
import EcommerceActiveOrdersTable from './EcommerceActiveOrdersTable.vue'

export default {
  components: {
    BRow,
    BCol,

    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,
    EcommerceWalletTable,
    EcommerceStatuses,
    EcommerceActiveOrdersTable,
  },
  data() {
    return {
      data: {},
    }
  },
  computed: {
    ...mapState({
      userName: state => state.auth.userName,
    }),
  },
  created() {
    // data
    // this.$http.get('/ecommerce/data')
    //   .then(response => {
    //     this.data = response.data
    //
    //     // ? Your API will return name of logged in user or you might just directly get name of logged in user
    //     // ? This is just for demo purpose
    //     this.data.congratulations.name = this.username
    //   })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
